import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Snackbar, Grid, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const STORAGE_KEY = 'popupDismissedAt';
const HIDE_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

const useStyles = makeStyles((theme) => ({
  snackbarContent: {
    background: 'transparent',
    boxShadow: 'none',
    padding: 0,
  },
  popupContainer: {
    position: 'relative',
    display: 'inline-block',
    borderRadius: '10px',
    overflow: 'hidden',
    height: '350px',
    width: '500px',
  },
  closeButton: {
    paddingBottom: '5px',
    cursor: 'pointer',
    color: theme.workwaveBlue,
    fontSize: '48px',
    transition: 'all .3s ease-out',
    '&:hover': {
      paddingTop: '5px',
      cursor: 'pointer',
      transform: 'rotate(180deg) ',
    },
  },
}));

const PopupContent = ({ image, link, internalLink, handleClose }) => {
  const classes = useStyles();

  return (
    <div className={classes.popupContainer}>
      <Grid container justifyContent='flex-end'>
        <CloseIcon
          fontSize='large'
          className={classes.closeButton}
          onClick={handleClose}
        />
      </Grid>
      {internalLink ? (
        <Grid container>
          <Link to={link} style={{ textDecoration: 'none' }}>
            <GatsbyImage
              image={image.asset.gatsbyImageData}
              alt='Popup Image'
              imgStyle={{ objectFit: 'contain', borderRadius: '10px' }}
            />
          </Link>
        </Grid>
      ) : (
        <Grid container>
          <a href={link} style={{ textDecoration: 'none' }} target='_blank'>
            <GatsbyImage
              image={image.asset.gatsbyImageData}
              alt='Popup Image'
              imgStyle={{ objectFit: 'contain', borderRadius: '10px' }}
            />
          </a>
        </Grid>
      )}
    </div>
  );
};

export const BottomLeftPopup = ({ popUp }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  //stuff for keeping it closed for 24 hours after closing it
  useEffect(() => {
    const lastDismissed = localStorage.getItem(STORAGE_KEY);
    const now = Date.now();

    if (!lastDismissed || now - lastDismissed > HIDE_DURATION) {
      const timer = setTimeout(() => setOpen(true), 2000);
      return () => clearTimeout(timer);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
    localStorage.setItem(STORAGE_KEY, Date.now());
  };

  const { image, link, internalLink } = popUp;
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={open}
      onClose={handleClose}
      ContentProps={{
        className: classes.snackbarContent,
      }}
      message={
        <PopupContent
          image={image}
          link={link}
          internalLink={internalLink}
          handleClose={handleClose}
        />
      }
    />
  );
};
